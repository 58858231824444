import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, BlogImage } from '../../../components';
import { HubspotCouponForm2024 } from '../..';

import poklonBon from '../../../images/poklon-bon-novi.png';

import './style.scss';

const CouponsForm = () => {
  return (
    <>
      <div id="forma" />
      <div>
        <Row
          className="coupons-action flex-column mt-5 justify-content-center align-items-center"
          noGutters
        >
          <Col className="mb-5" xs={10}>
            <div className="mt-5">
              <Text as="h2" bold center>
                <Localize id="coupons.Form.Title" />
              </Text>
            </div>
            <div className="mt-5 mb-5">
              <Text as="h3">
                <Localize id="coupons.Form.Subtitle" />
              </Text>
            </div>
            <HubspotCouponForm2024 />
          </Col>
        </Row>
        <Row
          className="flex-column mt-5 justify-content-center align-items-center"
          noGutters
        >
          <Col xs={10}>
            <div>
              <Text as="h2" bold center>
                <Localize id="coupons.End.Title.First" />
              </Text>
              <Text as="h2" bold center>
                <Localize id="coupons.End.Title.Second" />
              </Text>
            </div>
            <BlogImage
              imgAlt="Kokolingo poklon bon"
              imgTitle="Poklon bon"
              imgSrc={poklonBon}
              className="infographics"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(CouponsForm);
